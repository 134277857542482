// 事件节流
export const throttle = function (func, delay) {
  var prev = 0
  return function () {
    var context = this
    var args = arguments
    var now = Date.now()
    if (now - prev >= delay) {
      func.apply(context, args)
      prev = Date.now()
    }
  }
}

// 事件防抖
export const debounce = function (fun, wait) {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(fun, wait)
  }
}
