<template>
<div class="formCode">
  <template v-if="formList.length">
    <div class="newBuilding">
      <div class="control">
        <el-button class="btn" type="text" @click="$router.push({name: 'createform'})">
          <i class="el-icon-plus"></i>新建空白表单
        </el-button>
        <el-button class="btn" type="text" disabled>
          <i class="el-icon-s-management"></i>选择模板
        </el-button>
      </div>
      <div class="total">
        表单统计<span style="color:orangered">{{total}}</span>条
      </div>
    </div>
    <div class="warp">
      <div class="formList" v-for="(item, index) of formList" :key="index">
        <div class="formInfo">
          <div class="formName">{{ item.formName }}</div>
          <div class="updateTime">{{ item.updateTime.slice(0, 16) }}更新</div>
          <div class="trends">最新动态：暂无</div>
        </div>
        <div class="btnList">
          <el-button type="text" @click="preview(item)">预览</el-button>
          <el-button type="text" @click="edit(item)">编辑</el-button>
          <el-button type="text" @click="seeData(item)">数据</el-button>
          <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
            <el-button type="text" disabled>设置</el-button>
          </el-tooltip>
          <el-button type="text" @click="del(item)">删除</el-button>
        </div>
      </div>
      <el-pagination v-if="total > 0" style="text-align: right;margin-right: 20px;margin-top: 20px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="pageNo"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <div style="height: 50px"></div>
    </div>
    <el-dialog
      title="预览"
      :visible.sync="showPreview"
      width="500px" center>
      <div style="height: 100%;display: flex;">
        <img :src="previewImage" style="margin: auto" alt="扫码填表单">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPreview = false">取 消</el-button>
        <el-button @click="downLoadImg(previewImage)">保存二维码</el-button>
      </span>
    </el-dialog>
  </template>
  <template v-else>
    <div class="module">
      <div class="block" @click="$router.push('/createform')">
        <div class="iconBlock">
          <i class="el-icon-plus"></i>
        </div>
        <p>新建空白表单</p>
        <p>自定义内容编辑生成二维码</p>
      </div>
      <el-tooltip effect="dark" content="此功能尚未开启" placement="top">
        <div class="block" style="cursor: not-allowed;">
          <div class="iconBlock">
            <i class="el-icon-s-management"></i>
          </div>
          <p>选择模板</p>
          <p>从预设各类模板中快速生码</p>
        </div>
      </el-tooltip>
    </div>
  </template>
</div>
</template>

<script>
import { debounce } from '@/utils/index.js'
import { forms, remove, getFormAppletCode } from '@/api/createForm'
export default {
  name: 'formCode',
  data () {
    return {
      formList: [], // 表单数据
      pageSize: 10,
      pageNo: 1,
      total: 0,
      showPreview: false,
      previewImage: '',
      previewImageName: ''

    }
  },
  mounted () {
    this.init()
  },
  // activated () {
  //   this.pageNo = 1
  //   this.init()
  // },
  methods: {
    init () {
      forms({
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }).then(res => {
        if (res.code === 200) {
          this.formList = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    preview (obj) { // 预览
      getFormAppletCode({
        scene: 'id=' + obj.id + '&a=1&b=2'
      }).then(res => {
        if (res.code === 200) {
          this.showPreview = true
          this.previewImage = res.data
          this.previewImageName = obj.formName + '的二维码'
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    downLoadImg (imgsrc, name) {
      const that = this
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png', 0.7) // 得到图片的base64编码数据
        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = that.previewImageName + '.png' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
    edit (obj) { // 编辑
      this.$router.push({
        path: 'editform',
        query: {
          formId: obj.id
        }
      })
    },
    seeData (obj) { // 查看数据
      this.$router.push({
        path: 'formData',
        query: {
          formId: obj.id
        }
      })
    },
    del (obj) { // 删除
      this.$confirm('此操作不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove({
          formId: obj.id
        }).then(res => {
          if (res.code === 200 && res.data === true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 分页相关
    handleSizeChange (val) {
      this.pageSize = val
      this.pageNo = 1
      this.init()
    },
    handleCurrentChange (val) {
      this.pageNo = val
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./formCode.scss";
</style>
