var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formCode" },
    [
      _vm.formList.length
        ? [
            _c("div", { staticClass: "newBuilding" }, [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "createform" })
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v("新建空白表单 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "text", disabled: "" }
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-management" }),
                      _vm._v("选择模板 ")
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "total" }, [
                _vm._v(" 表单统计"),
                _c("span", { staticStyle: { color: "orangered" } }, [
                  _vm._v(_vm._s(_vm.total))
                ]),
                _vm._v("条 ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "warp" },
              [
                _vm._l(_vm.formList, function(item, index) {
                  return _c("div", { key: index, staticClass: "formList" }, [
                    _c("div", { staticClass: "formInfo" }, [
                      _c("div", { staticClass: "formName" }, [
                        _vm._v(_vm._s(item.formName))
                      ]),
                      _c("div", { staticClass: "updateTime" }, [
                        _vm._v(_vm._s(item.updateTime.slice(0, 16)) + "更新")
                      ]),
                      _c("div", { staticClass: "trends" }, [
                        _vm._v("最新动态：暂无")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "btnList" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.preview(item)
                              }
                            }
                          },
                          [_vm._v("预览")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.edit(item)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.seeData(item)
                              }
                            }
                          },
                          [_vm._v("数据")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Top Center 提示文字",
                              placement: "top"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "text", disabled: "" } },
                              [_vm._v("设置")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.del(item)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ])
                }),
                _vm.total > 0
                  ? _c("el-pagination", {
                      staticStyle: {
                        "text-align": "right",
                        "margin-right": "20px",
                        "margin-top": "20px"
                      },
                      attrs: {
                        "current-page": _vm.pageNo,
                        "page-sizes": [10, 20, 30, 50],
                        "page-size": _vm.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  : _vm._e(),
                _c("div", { staticStyle: { height: "50px" } })
              ],
              2
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "预览",
                  visible: _vm.showPreview,
                  width: "500px",
                  center: ""
                },
                on: {
                  "update:visible": function($event) {
                    _vm.showPreview = $event
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { height: "100%", display: "flex" } },
                  [
                    _c("img", {
                      staticStyle: { margin: "auto" },
                      attrs: { src: _vm.previewImage, alt: "扫码填表单" }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.showPreview = false
                          }
                        }
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.downLoadImg(_vm.previewImage)
                          }
                        }
                      },
                      [_vm._v("保存二维码")]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "module" },
              [
                _c(
                  "div",
                  {
                    staticClass: "block",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/createform")
                      }
                    }
                  },
                  [
                    _vm._m(0),
                    _c("p", [_vm._v("新建空白表单")]),
                    _c("p", [_vm._v("自定义内容编辑生成二维码")])
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: "此功能尚未开启",
                      placement: "top"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "block",
                        staticStyle: { cursor: "not-allowed" }
                      },
                      [
                        _c("div", { staticClass: "iconBlock" }, [
                          _c("i", { staticClass: "el-icon-s-management" })
                        ]),
                        _c("p", [_vm._v("选择模板")]),
                        _c("p", [_vm._v("从预设各类模板中快速生码")])
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBlock" }, [
      _c("i", { staticClass: "el-icon-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }